<template>
  <div class="manager-container">
    <div class="left" v-if="hasSysAdminPermission">
      <div class="title">
        <base-block-title
          :title="$t('company')"
          :sub-title="companyList.length">
        </base-block-title>
      </div>
      <div class="content">
        <company-item
          v-for="item in companyList"
          :key="item.id"
          :company="item"
          :active="item.id === currentCompany.id"
          @click.native="currentCompany = item">
        </company-item>
      </div>
    </div>
    <div class="middle">
      <div class="middle-title">
        <base-block-title
          :title="$t('role')"
          :sub-title="total">
        </base-block-title>
      </div>
      <div class="middle-content">
        <span
          :class="{active:item.id === currentRole.id}"
          v-for="item in roleList"
          :key="item.id"
          @click="currentRole=item"
          style="position: relative; border: 1px solid #DCDFE6">
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="right">
      <div class="title-button">
        <base-block-title
          :title="$t('permissionList')"
          :sub-title="currentRole.id?currentRole.name:''"
          :sub-title2="companyStatus">
        </base-block-title>
        <div class="button-block">
          <!--保存-->
          <el-button
            v-if="hasPermission($permissionFunctionCode.EDIT)"
            type="primary"
            round
            plain
            @click="savePermission">
            {{ $t('save') }}
          </el-button>
        </div>
      </div>
      <div class="table-block">
        <el-table
          v-loading="tableLoading"
          :data="currentRole.id ? displayMenuList : []"
          row-key="id"
          default-expand-all
          height="100%"
          stripe
          :default-sort="{prop:'sort'}">
          <base-no-data slot="empty"></base-no-data>
          <el-table-column
            type="index"
            :label="$t('serialNum')"
            align="center"
            width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('menuName')"
            width="230">
            <template slot-scope="scope">
              <span>{{ $t(scope.row.name) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            fixed="right"
            :label="$t('permissionList')">
            <template slot-scope="scope">
              <el-checkbox
                v-if="!scope.row.children.length"
                style="margin-right: 15px"
                v-model="scope.row.checkAll"
                @change="handleCheckAllChange($event,scope.row)">
                {{ $t('checkAll') }}
              </el-checkbox>
              <el-checkbox-group
                style="display: inline-block"
                v-model="scope.row.checkedFunctions"
                @change="handleFunctionChange($event,scope.row)">
                <el-checkbox
                  style="margin-right: 15px"
                  v-for="item in (scope.row.functions || [])"
                  :label="item.id"
                  :key="item.id">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <base-form-add-or-edit
      v-if="addDialogVisible"
      :visible="addDialogVisible"
      :url-save="saveRoleUrl"
      :url-update="saveRoleUrl"
      :fields="fields"
      labelWidthCn="100"
      labelWidthEn="200"
      @closed="addDialogVisible=false"
      @submit-success="getUpdateInfo(currentCompany)">
    </base-form-add-or-edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'

export default {
  name: 'UserManager',
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      // fields: [
      //   {
      //     fieldName: 'department',
      //     label: 'department',
      //     disabled: true
      //   },
      //   {
      //     fieldName: 'departmentId',
      //     fieldType: 'invisible',
      //     label: 'department'
      //   },
      //   {
      //     fieldName: 'name',
      //     label: 'username',
      //     rules: 'required'
      //   },
      //   {
      //     fieldName: 'gender',
      //     default: 'MALE',
      //     formConfig: {
      //       type: 'radio',
      //       options: [
      //         {
      //           value: 'MALE',
      //           text: 'male'
      //         },
      //         {
      //           value: 'FEMALE',
      //           text: 'female'
      //         }
      //       ]
      //     }
      //   },
      //   {
      //     fieldName: 'mobile'
      //   },
      //   {
      //     fieldName: 'position'
      //   },
      //   {
      //     fieldName: 'sort',
      //     fieldType: 'Number',
      //     default: null,
      //     formConfig:
      //       {
      //         type: 'inputNumber',
      //         min: 1
      //       }
      //   },
      //   {
      //     fieldName: 'enable',
      //     fieldType: 'Boolean',
      //     default: true,
      //     formConfig:
      //       {
      //         type: 'switch'
      //       }
      //   }
      // ],
      fields: [
        {
          fieldName: 'orgId',
          fieldType: 'invisible'
        },
        {
          fieldName: 'name',
          label: 'roleName',
          rules: 'required'
        },
        {
          fieldName: 'code',
          label: 'code',
          rules: 'required'
        },
        {
          fieldName: 'description',
          label: 'desc'
        },
        {
          fieldName: 'enable',
          fieldType: 'Boolean',
          default: true,
          formConfig:
            {
              type: 'switch'
            }
        },
        {
          fieldName: 'internal',
          fieldType: 'invisible',
          default: false
        }
      ],
      menuList: [],
      companyList: [],
      roleList: [],
      currentCompany: {},
      currentRole: {},
      currentUser: {},
      roleVisible: false,
      selectedRoles: [],
      selectedFunctions: [],
    }
  },

  created () {
    this.queryUrl = this.$apis.queryUser
    this.saveUrl = this.$apis.saveUser
    this.deleteUrl = this.$apis.deleteUser
    //  编辑角色的url
    this.saveRoleUrl = this.$apis.saveRole
    this.getMenuList()
    this.getCompanies()
  },

  computed: {
    ...mapState(['permissions']),
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    },
    companyStatus () {
      return this.getCompanyStatusText(this.currentCompany)
    },
    displayMenuList () {
      if (this.currentRole.code !== this.$roleCode.systemAdmin) {
        return this.menuList.filter(item => {
          return item.name !== 'AUDIT_LOGGING'
        })
      }

      return this.menuList
    }
  },

  methods: {
    getCompanies () {
      /**
       * 获取当前用户的企业列表
       */
       const assignCurrentCompany = async () => {
        if(this.companyList.length <= 0) return
        this.currentCompany = this.companyList[0]
      }

      return new Promise((resolve, reject) => {
        this.fullScreenLoading = true
        if(this.hasSysAdminPermission) {
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}?pageNumber=1&pageSize=99999&offline=false`,
          }).then(res => {
            if (res?.code === 1000) {
              this.companyList = res?.data?.records || []
              this.companyList.unshift({
                id: null,
                companyName: this.$t('builtSystemOrg'),
                enable: true,
                active: 1
              })
              assignCurrentCompany().then(() => {
                this.fullScreenLoading = false
                resolve()
              })
            }
          })
        }
        else {
          const { currentCompany: globalCurrentCompany } = this.$store.state
          this.companyList = globalCurrentCompany ? [globalCurrentCompany] : []
          assignCurrentCompany().then(() => {
            this.fullScreenLoading = false
            resolve()
          })
        }
      })
    },

    getRolesByCompany (companyId = undefined, internal = undefined) {
      /**
       * 获取企业下面的所有角色
       * @param {String} companyId:企业ID
       * @param {Boolean} internal:内置角色
       */
      this.tableLoading = true
      const url = `${this.$apis.roleBaseUrl}?pageNumber=1&pageSize=99999` + (companyId ? `&organizationId=${companyId}` : `&internal=true`)
      this.$request.get({ url }).then(res => {
        if (res?.code === 1000) {
          let roleList = res.data.records || []
          if (!this.hasSysAdminPermission) {
            // 不是超级管理员，则不允许修改内置的角色权限
            roleList = roleList.filter(item => item.code !== this.$roleCode.companyAdmin)
          }
          this.roleList = roleList
          if (this.roleList.length) {
            this.currentRole = this.roleList[0]
          }
          this.total = res.data.total
        }
      }).finally(() => {
        //  如果不是超级管理员，则去除部分功能
        if (!this.hasSysAdminPermission) {
          this.menuList = this.menuList.filter(item => {
            return item.name !== 'AUDIT_LOGGING'
          })
          let sysAdmin = this.menuList.filter(item => {
            return item.name === 'SYSTEM_MANAGEMENT'
          })
          let menuChild = sysAdmin.length > 0 ? sysAdmin[0].children : []
          menuChild = menuChild.filter((item) => {
            return item.name !== 'MENU_MANAGEMENT' && item.name !== 'FUNCTION_MANAGEMENT' && item.name !== 'FILE_MANAGEMENT' && item.name !== 'Calendar Management' && item.name !== 'SYSTEM_SETTING_MANAGEMENT'
          })
          this.menuList.forEach(item => {
            if (item.name === 'SYSTEM_MANAGEMENT') {
              item.children = menuChild
            }
          })
        } 
        this.tableLoading = false
      })
    },

    getMenuList () {
      /**
       * 获取所有菜单数据
       */
      this.$request.post({
        url: this.$apis.queryMenuTree
      }).then(data => {
        if (data?.code === 1000) {
          const menuList = data.data || []
          const setChildren = (data) => {
            data.forEach(item => {
              if (item.children?.length) {
                setChildren(item.children)
              } else {
                item.children = []
              }
            })
          }
          const setFunction = (data) => {
            data.forEach(item => {
              if (item.functions?.length) {
                setFunction(item.children)
              } else {
                item.functions = []
              }
            })
          }
          const setCheckAll = (data) => {
            data.forEach(item => {
              item.checkAll = false
              item.checkedFunctions = []
              if (item.children?.length) {
                setCheckAll(item.children)
              }
            })
          }
          setChildren(menuList)
          setFunction(menuList)
          setCheckAll(menuList)
          this.menuList = menuList
        }
      })
    },

    getFunctionByRole (roleId) {
      /**
       * 根据角色获取功能点
       */
      this.$request.get({
        url: `${this.$apis.permissionBaseUrl}?pageNumber=1&pageSize=99999&roleIds=${roleId}`
      }).then(res => {
        if (res?.code === 1000) {
          const functions = res.data.records || []
          const finishedFunctions = {}
          functions.forEach(item => {
            if (finishedFunctions[item.menuId]) {
              finishedFunctions[item.menuId].push(item.functionId)
            } else {
              finishedFunctions[item.menuId] = []
              finishedFunctions[item.menuId].push(item.functionId)
            }
          })
          const setCheckedPermission = data => {
            data.forEach(item => {
              if (item.children?.length) {
                setCheckedPermission(item.children)
              } else {
                item.checkedFunctions = finishedFunctions[item.id] || []
              }
            })
          }
          const setCheckAll = (data) => {
            data.forEach(item => {
              if (item.children?.length) {
                setCheckAll(item.children)
              } else {
                item.checkAll = item.checkedFunctions.length >= item.functions.length
              }
            })
          }
          setCheckedPermission(this.menuList)
          setCheckAll(this.menuList)
        }
      })
    },

    handleCheckAllChange (value, row) {
      /**
       * 某个菜单功能点全选
       */
      row.checkAll = value
      row.checkedFunctions = value ? row.functions.map(item => item.id) : []
    },

    handleFunctionChange (value, row) {
      /**
       * 某个菜单功能点变化
       */
      row.checkAll = value.length >= row.functions.length
      const menuFunction = row.functions.find(item => item.code === 'menu')
      const menuFunctionId = menuFunction?.id
      if (value.length && menuFunctionId && !row.checkedFunctions.includes(menuFunctionId)) {
        row.checkedFunctions.push(menuFunctionId)
      }
    },

    savePermission () {
      /**
       * 保存权限
       */
      const _this = this
      this.$confirm(this.$t('submitPermissionTip'), this.$t('editPermission'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const permissionData = this.$_.cloneDeep(this.menuList)
        const permissionList = []
        const getPermissionData = (data) => {
          data.forEach(item => {
            if (item.children.length) {
              getPermissionData(item.children)
            } else {
              (item.checkedFunctions || []).forEach(subItem => {
                const permissionObj = {
                  roleId: _this.currentRole.id,
                  menuId: item.id,
                  functionId: subItem
                }
                permissionList.push(permissionObj)
              })
            }
          })
        }
        getPermissionData(permissionData)
        if (!permissionList.length) {
          permissionList.push({
            roleId: _this.currentRole.id,
            menuId: null,
            functionId: null
          })
        }
        this.$oneLoading.show('Saving...')
        this.$request.post({
          url: this.$apis.batchSavePermission,
          data: permissionList
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success('Changes saved.')
          }
        }).finally(() => {
          this.$oneLoading.hide()
        })
      }).catch(() => {
      })
    },

    handleRole (command) {
      /**
       * 操作角色
       */
      const doList = {
        edit: this.editRowDataMixin,
        delete: this.deleteRowDataMixin
      }
      doList[command]()
    },

    addRowData () {
      /**
       * 增加角色
       */
      this.fields = this.$options.data().fields
      this.fields[0].default = this.currentCompany.id

      if (this.currentCompany.id === null && this.hasSysAdminPermission) {
        const internalField = this.fields.find(field => field.fieldName === 'internal')
        internalField.default = true
      }

      this.addDialogVisible = true
    },

    getUpdateInfo (currentCompany) {
      /**
       * 获取表单的新信息
       */
      if (currentCompany.id === null && this.hasSysAdminPermission) {
        this.getRolesByCompany(undefined, true)
      }
      else {
        this.getRolesByCompany(currentCompany.id)
      }
    },
    deleteCallBack (message) {
      if (message === 'success') {
        this.getUpdateInfo(this.currentCompany)
      }
    },
    deleteRole (item, getRolesByCompany) {
      /**
       * 删除角色
       */
      this.deleteUrl = this.$apis.deleteRole
      this.deleteRowDataMixin(item, this.deleteCallBack)
    }
  },

  watch: {
    'currentCompany.id': {
      handler (value) {
        if(value === undefined) return;
        if(value === null && this.hasSysAdminPermission) {
          this.getRolesByCompany(undefined, true)
        }
        else {
          this.getRolesByCompany(value)
        }
      },
      immediate: true
    },

    'currentRole.id': {
      handler (value) {
        if (value) {
          this.getFunctionByRole(value)
        }
      },
      immediate: true
    },

    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "index";

  .middle-title {
    display: flex;
    justify-content: space-between;
  }
</style>
